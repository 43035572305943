.more-info-banner {
    height: 85px;
    background-color: #EC4E6A;
    color: white;
    cursor: pointer;
    text-align: center;
}

.more-info-banner .banner-text {
    font-size: 25px;
}

@media screen and (max-width: 991px) {
    .more-info-banner .banner-text {
        font-size: 20px;
    }
}

@media screen and (max-width: 650px) {
    .more-info-banner .banner-text {
        font-size: 14px;
    }
}
