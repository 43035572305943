.custom-input-label {
    font-size: 0.8em;
    line-height: 0.8em;
    margin-bottom: 0;
    color: #7C7C7C;
}

.text-area-count {
    position: relative;
    bottom: 20px;
    z-index: 1000;
    display: block;
    text-align: right;
    padding-right: 10px;
    color: #282727;
    font-size: 0.6em;
}
