@media screen and (min-width: 431px) {
    .not-found-container {
        width: 100vw;
        height: 100vh;
    }

    .not-found-something-went-wrong {
        margin-top: 20vh;
        font-size: 40px;
    }

    .not-found-sub-text {
        font-size: 18px;
    }

    .not-found-404 {
        font-size: 300px;
        font-weight: bolder;
        background: -webkit-linear-gradient(#804EEC, #0031B4);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .not-found-button-container {
        margin-bottom: 20vh;
        font-size: 40px;
    }

    .not-found-upside-down {
        width: 300px;
        height: 300px;
        margin-top: -50px;
    }
}

@media screen and (max-width: 431px) {
    .not-found-container {
        width: 100vw;
        height: 100vh;
    }

    .not-found-something-went-wrong {
        margin-top: 25vh;
        font-size: 27px;
    }

    .not-found-sub-text {
        font-size: 15px;
    }

    .not-found-404 {
        font-size: 150px;
        font-weight: bolder;
        background: -webkit-linear-gradient(#804EEC, #0031B4);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .not-found-button-container {
        margin-bottom: 20vh;
        font-size: 16px;
    }

    .not-found-upside-down {
        width: 150px;
        height: 150px;
        margin-top: -25px;
    }
}
