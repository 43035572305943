.user-background {
    width: 100vw;
    height: 100vh;
    top: 0;
    position: fixed;
    background-image: url("../../../../public/sign_in_bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(60px);
    -webkit-filter: blur(60px);
    z-index: -1;
}

.user-content .title {
    width: 100vw;
    margin-top: 84px;
    text-align: center;
}

.user-content .page-content {
    position: relative;
    width: 650px;
    margin-left: calc(50vw - 325px);
    top: 10vh;
}

.user-content .page-content .form {
    background-color: white;
    border-radius: 10px;
    padding: 35px 32px 54px 32px;
    box-shadow: 0 4px 15px 1px rgba(0, 0, 0, 0.15);
}

.user-content .page-content .footer {
    font-size: 18px;
    margin-bottom: 100px;
}

.user-content .page-content .footer .link {
    color: #36198A;
    text-decoration: none;
}

.user-content div.separator-text {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #E1E1E1;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.user-content div.separator-text span {
    background: #ffffff;
    padding: 0 10px;
    color: #A1A1A1;
}

.oauth-user-action {
    background-color: white;
    border: 3px solid #F1ECFF;
    border-radius: 5px;
    color: #282727;
    width: 100%;
    font-size: 18px;
    padding: 10px;
}

.oauth-user-action:hover {
    background-color: #F1ECFF;
    border: 3px solid #F1ECFF;
    color: #282727;
}

.user-content .page-content .user-action {
    width: 150px;
    padding: 10px;
}

@media screen and (max-width: 450px) {
    .user-content .page-content {
        width: 100vw;
        margin-left: 0;
        top: 5vh;
    }

    .user-content .page-content .footer {
        font-size: 18px;
        margin-bottom: 30px;
    }

    .user-content .title {
        margin-top: 30px;
    }

    .user-content .page-content .form {
        padding: 35px 32px 54px 32px;
    }
}

@media screen and (min-width: 451px) and (max-width: 1024px) {
    .user-content .page-content {
        top: 10vh;
        width: 550px;
        margin-left: calc(50vw - 275px);
    }

    .user-content .page-content .footer {
        font-size: 18px;
        margin-bottom: 30px;
    }

    .user-content .title {
        margin-top: 50px;
    }
}

@media screen and (min-width: 0px) {
    .user-content .title .logo {
        font-size: 40px;
        letter-spacing: -3px;
    }
}
