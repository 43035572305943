body {
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 40px;
  line-height: 54px;
}

span.subtext {
  font-size: 14px;
  line-height: 19px;
}

.logo {
  font-weight: 800;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(172.88deg, #804EEC 17.41%, #0031B4 75.16%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form-check-input.custom:checked {
  background-color: #36198A;
}

@media screen and (max-width: 991px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .logo {
    font-size: 20px;
    letter-spacing: -1px;
  }

  .profile-username {
    font-weight: 400;
    font-size: 20px;
  }

  .btn-custom, .btn-outline-custom {
    font-size: 0.8em;
  }
}

@media screen and (min-width: 992px) {
  .desktop {
    display: block !important;
  }

  .mobile {
    display: none !important;
  }

  .logo {
    font-size: 40px;
    letter-spacing: -3px;
  }

  .profile-username {
    font-weight: 600;
    font-size: 24px;
  }
}

.btn-custom {
  background: linear-gradient(269deg, #804EEC 3.25%, #0031B4 110.51%);
  border-radius: 6px;
  color: white;
}

.btn-outline-custom {
  position: relative;
  border: 1px solid #0031B4;
  border-radius: 6px;
  color: #36198A;
}

.custom-input {
  border-radius: 5px;
  background-color: #F1ECFF;
  border: 0;
}

input.custom-input, select.custom-input {
  height: 48px;
}

button.custom-input:hover, button.custom-input:focus  {
  background-color: #F1ECFF;
}

.nav-link:hover {
  color: #36198A;
}
