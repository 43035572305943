.advantages-item {
    background-color: white;
    padding: 40px;
    fill: #FFF;
    stroke-width: 1px;
    stroke: #F3F4FE;
    filter: drop-shadow(0px 50px 115px rgba(165, 153, 209, 0.30));
    border-radius: 15px;
    height: 100%;
    position: relative;
}

@media screen and (min-width: 1750px) {
    .advantages-item {
        min-height: 400px;
    }
}

@media screen and (max-width: 1749px) and (min-width: 1150px) {
    .advantages-item {
        min-height: 450px;
    }
}

@media screen and (max-width: 1149px) and (min-width: 992px) {
    .advantages-item {
        min-height: 500px;
    }
}

@media screen and (max-width: 991px) and (min-width: 510px) {
    .advantages-item {
        min-height: 400px;
    }
}

@media screen and (max-width: 509px) and (min-width: 350px) {
    .advantages-item {
        min-height: 450px;
    }
}

@media screen and (max-width: 349px) and (min-width: 250px) {
    .advantages-item {
        min-height: 550px;
    }

    .advantages-subtitle {
        padding-top: 20px
    }

    .advantages-image {
        max-width: 80%;
    }
}

.advantages-body {
    margin-top: 40px;
}

.advantages-title {
    color: #282727;
    font-size: 24px;
    position: absolute;
    top: 200px;
    padding-right: 20px;
}

.advantages-subtitle {
    position: absolute;
    top: 275px;
    color: #6E6E6E  ;
    font-size: 17px;
    padding-right: 20px;
}

@media screen and (min-width: 1563px) {
    .landing-desktop {
        display: block !important;
    }

    .landing-tablet {
        display: none !important;
    }

    .landing-mobile {
        display: none !important;
    }
}

@media screen and (max-width: 1562px) and (min-width: 1150px) {
    .landing-desktop {
        display: none !important;
    }

    .landing-tablet {
        display: block !important;
    }

    .landing-mobile {
        display: none !important;
    }
}

@media screen and (max-width: 1149px) {
    .landing-desktop {
        display: none !important;
    }

    .landing-tablet {
        display: none !important;
    }

    .landing-mobile {
        display: block !important;
    }
}
